export const useFoods = () => {
  return [
    {
      id: 1,
      name: 'Мясо',
    },
    {
      id: 2,
      name: 'Рыба',
    },
  ]
}